<template>
  <v-navigation-drawer
    app
    fixed 
    permanent
    width="300"
    class="#fff"
  >
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img
            src="/logo.jpeg"
            alt="Logo"
          >
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title 
            class="text--logo" 
            style="font-size: 22px"
          >
            Fortune Connect
          </v-list-item-title>

          <v-list-item-subtitle
            class="body-2"
          >
            {{ client.business_name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list
      dense
      subheader
    >
      <template v-for="(links, submenu) in sidebar">
        <v-subheader
          :key="`submenu-${submenu}`"
          class="caption mt-4 mx-2"
        >
          {{ submenu }}
        </v-subheader>

        <template v-for="(link, index) in links">
          <v-list-item
            v-if="!link.children"
            :key="`main-${submenu}-${index}`"
            :to="`/${link.to}`"
          >
            <v-list-item-icon>
              <v-icon small>mdi-{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="body-2">
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            no-action
            :key="`main-${index}`"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon small>mdi-{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="body-2">
                {{ link.name }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="(child, i) in link.children"
              :key="`sub-${i}`"
              :to="`/${child.to}`"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      menu: {
        // 'ANALYTICS': [
        //   { name: 'Dashboard', icon: 'chart-bar', to: 'iam/partnerships', permission: 'iam.partnerships.index' },
        //   { name: 'Reports', icon: 'microsoft-excel', to: 'insurance/orders', permission: 'insurance.orders.index' },
        // ],

        'OPERATIONS': [
          // { name: 'Orders', icon: 'package-variant-closed-check', to: 'insurance/orders', permission: 'insurance.orders.index' },
          { name: 'Orders', icon: 'package-variant-closed-check', to: 'retail/orders', permission: 'insurance.orders.index' },
        ],
      },
    }
  },

  computed: {
    sidebar () {
      for (let sidemenu in this.menu) {
        const links = this.menu[sidemenu]

        this.menu[sidemenu] = links.filter((link, index) => {
          if (link.children) {
            this.menu[sidemenu][index]['children'] = this.menu[sidemenu][index].children.filter((child) => {
              return vault.can(child.permission)
            })
            return this.menu[sidemenu][index]['children'].length !== 0
          } else {
            return vault.can(link.permission)
          }
        })

        if (this.menu[sidemenu].length === 0) {
          delete this.menu[sidemenu]
        }
      }

      return this.menu
    },

    client () {
      return vault.take('client')
    },
  },
}
</script>